import React, { Component } from 'react';
import { observer } from 'mobx-react';
import uploadIcon from '../Images/upload-icon.png';

import UploadModel from '../Models/loadModels/UploadModel';
import Helper from '../Helpers/helper';
import UploadQueueModel from '../Models/UploadQueueModel';
import DatePickerModel from '../Models/DatePickerModel';

@observer
class UploadSubsection extends Component {
  handleUploadFiles = e => {
    const { subSectionData, dest, additionalData = null } = this.props;
    const files = e.target.files;

    if (dest === 'Banks') {
      const isMonthToDate = Helper.isBankMonthToDate(dest, subSectionData.id);

      if (!isMonthToDate && files.length) {
        UploadQueueModel.setPendingLocalFile(files[0]);
        UploadQueueModel.setPendingLocalBankId(subSectionData.id);

        DatePickerModel.toggleDatePicker(subSectionData.id, true);

        return;
      }
    }

    UploadModel.handleFilesUpload(files, dest, subSectionData.id, false, false, additionalData);
  };

  render() {
    const { dest, additionalData = null } = this.props;
    const destinationClass = additionalData && additionalData.parentDest ? additionalData.parentDest : dest;
    return (
      <div className={`upload-section ${destinationClass}`}>
        <input type="file" multiple onChange={this.handleUploadFiles} className="upload-section__input" />
        <span className="upload-section__icon">
          <img src={uploadIcon} alt="" />
        </span>
        <span className="upload-section__text">
          Drag files here or click <span className="underline">here</span> to upload
        </span>
      </div>
    );
  }
}

export default UploadSubsection;
