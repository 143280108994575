import { observable, action } from 'mobx';
import { NotificationManager } from '../Components/popups/react-notifications/index';
import RootModel from './RootModel';
import fetchData from '../Services/fetchData';
import UploadModel from './loadModels/UploadModel';
import { DEAL_ID } from '../constants/api';
import Helper from "../Helpers/helper";
import {getMonthReverce} from "../Helpers/convertData";

class UploadQueueModel {
  @observable pendingUploads = [];
  @observable uploadTimer = null;
  @observable pendingLocalFile = null;
  @observable pendingLocalBankId = null;
  @observable reloadBanks = false;
  uploadDelay = process.env.UPLOAD_DELAY_MS || 1000;

  @action setPendingLocalFile(file) {
    this.pendingLocalFile = file;
  }

  @action setPendingLocalBankId(bankId) {
    this.pendingLocalBankId = bankId;
  }

  @action addToQueue(fileObject) {
    this.pendingUploads.push(fileObject);
    this.startUploadTimer();
  }

  @action setReloadBanks(reloadPage) {
    this.reloadBanks = reloadPage;
  }

  @action sendMessageOnTimer(timerMessage) {
    if(!window.parent) {
      if (timerMessage.uploadStarted) {
        window.onbeforeunload = function() {
          var message = "Are you sure you want to navigate away from this page?\n\nUploading files may not be there on complete.\n\nPress OK to continue or Cancel to stay on the current page.";
          if (window.confirm(message)) return true;
          else return false;
        }
      } else if (timerMessage.uploadCompleted) {
        window.onbeforeunload = null;
      }
      return;
    }
    window.parent.postMessage(timerMessage, '*');
  }

  /*
   * Start or reset the timer that triggers the queue upload
   */
  @action startUploadTimer() {
    if (this.uploadTimer) {
      clearTimeout(this.uploadTimer);
    } else {
      this.sendMessageOnTimer({uploadStarted: true});
    }

    this.uploadTimer = setTimeout(() => {
      this.uploadAllPending();
    }, this.uploadDelay);
  }

  /*
   * Upload all pending files in a arallel queue using Promise.all()
   */
  @action async uploadAllPending() {
    const filesToUpload = this.pendingUploads.slice();

    if (this.uploadTimer) {
      clearTimeout(this.uploadTimer);
      this.uploadTimer = null;
    }

    if (!filesToUpload.length) return;

    try {
      const uploadedFiles = await Promise.all(filesToUpload.map(item => this.uploadSingleFile(item)));


      this.sendMessageOnTimer({uploadCompleted: true, hasChanged: true});

      if (this.reloadBanks) {
        await RootModel.getBanks()
      }

      if (uploadedFiles.length) {
        RootModel.updateBanksFiles(uploadedFiles);
      }

      NotificationManager.success('All files successfully uploaded!');
      this.pendingUploads = [];
    } catch (err) {
      NotificationManager.error('Some files failed to upload: ' + err.message);
    }
  }

  /*
   * Perform the actual API call to upload one file
   */
  uploadSingleFile = async ({ file, rawFile, bankId, month, year, isExisting }) => {
    try {
      /*
        Check we have bank months and if not create a bank month
       */

      let bankDateId = Helper.getBankDocumentsDateId(bankId, month, year);
      if (!bankDateId) {
        bankDateId = await this.createBankDetail(bankId, month, year);
      }
      /*
       * If we have rawFile, it is a brand-new file from user's computer
       */
      if (!isExisting && rawFile) {
        return await this.uploadNewFile({ file, rawFile, bankDateId, month, year });
      } else {
        /*
         * ELSE it is an existing doc from FileBin or some other section
         */

        return await this.updateExistingFile({ file, bankDateId, month, year });
      }
    } catch (error) {
      if (!isExisting) {
        RootModel.removeFileFromBankById(bankId, file.id);
        NotificationManager.error(`Upload failed for file ${file.title || file.id}: ${error.message}`);
      }

      throw error;
    }
  };

  async createBankDetail(bankId, month, year) {
    let data = {
      bank_account_id: bankId,
      month: getMonthReverce(month),
      year,
    };

    const createAccountDetailsResponse = await fetchData('bank_account_details/create', data);
    const bankDateId = await createAccountDetailsResponse.json()
    return bankDateId;
  }

  uploadNewFile = async ({ file, rawFile, bankDateId, month, year }) => {
    const base64data = (await UploadModel.toBase64(rawFile)).split('base64,');
    const document = base64data[1];

    const payload = {
      parent_type: 'BankAccountDetail',
      parent_id: bankDateId,
      name: rawFile.name,
      document,
      month,
      year,
    };

    const createFileResponse = await fetchData('documents/create', payload);
    const newFileId = await createFileResponse.json();

    const existingFileResponse = await fetchData('documents', {
      view_file: newFileId,
      parent_type: 'Deal',
      parent_id: DEAL_ID,
    });
    const [realDoc] = await existingFileResponse.json();

    return {
      tempId: file.id,
      realId: realDoc.id,
      realUrl: realDoc.document_url,
      isExisting: false,
    };
  };

  updateExistingFile = async ({ file, bankDateId, month, year }) => {
    const payload = {
      id: file.id,
      parent_type: 'BankAccountDetail',
      parent_id: bankDateId,
      month,
      year,
    };

    await fetchData('documents/update', payload);

    return {
      tempId: file.id,
      realId: file.id,
      realUrl: file.url,
      isExisting: true,
    };
  };
}

export default new UploadQueueModel();
