import { observable, action } from 'mobx';
import uuid from 'uuid';
import Helper from '../Helpers/helper';
import RootModel from './RootModel';
import DropEndModel from './DropEndModel';
import UpdateModel from './UpdateModel';
import { getMonthReverce, months } from '../Helpers/convertData';
import UploadQueueModel from './UploadQueueModel';
import UploadModel from "./loadModels/UploadModel";

class DatePickerModel {
  MONTHS = 12;
  @observable months = this.setYearForMonths();
  @observable isFromSingleUpload = false;


  @action setIsFromSingleUpload() {
    this.isFromSingleUpload = true;
  }

  setYearForMonths() {
    let currDate = new Date();
    let currMonth = currDate.getMonth(),
      currYear = currDate.getFullYear();

    let monthsArr = months.map((month, index) => {
      return {
        id: uuid.v4(),
        name: month,
        year: index <= currMonth ? currYear : currYear - 1,
      };
    });

    return monthsArr;
  }

  @action toggleDatePicker(subSectionId, doShow) {
    let newBanks = RootModel.Banks.map(bank => {
      if (subSectionId && bank.id.toString() === subSectionId.toString()) {
        if (doShow) {
          bank.isDatePickerShown = doShow;
        } else {
          bank.isDatePickerShown = !bank.isDatePickerShown;
        }
      } else {
        bank.isDatePickerShown = false;
      }
      return bank;
    });

    RootModel.Banks = newBanks;
  }

  @action setDraggableData(data) {
    this.draggableData = data;
  }

  @action setDate(monthId) {
    const dateObj = this.months.find(m => m.id === monthId);
    if (!dateObj) return;

    const month = dateObj.name;
    const year = dateObj.year;

    if (this.isFromSingleUpload) {
      UploadModel.uploadBankDocuments(month, year);
      return;
    }

    const draggableData = DropEndModel.draggableData;
    const rawFile = UploadQueueModel.pendingLocalFile;

    /*
     * CASE A: Dragging an existing file from FileBin
     */
    if (draggableData) {
      const { destination, draggableId } = draggableData;
      const file = Helper.findFile(destination, draggableId);
      file.isUploading = true;
      UpdateModel.updateDocumentsPlace(file, 'Banks', destination.droppableId, 'FileBinFiles');

      this.toggleDatePicker(destination.droppableId, false);

      file.month = getMonthReverce(month);
      file.year = year;
      UploadQueueModel.addToQueue({
        file,
        bankId: Number(destination.droppableId),
        month: getMonthReverce(month),
        year,
        isExisting: true,
      });




      RootModel.addFileToBank(Number(destination.droppableId), file);

      DropEndModel.setDraggableData(null);
      return;
    }

    /*
     * CASE B: A brand-new file from user’s computer
     */
    if (rawFile) {
      const revercedMonth = getMonthReverce(month);
      const newFile = RootModel.createNewBankFile(UploadQueueModel.pendingLocalBankId, rawFile, revercedMonth, year);

      this.toggleDatePicker(UploadQueueModel.pendingLocalBankId, false);

      UploadQueueModel.addToQueue({
        file: newFile,
        rawFile,
        bankId: UploadQueueModel.pendingLocalBankId,
        month: getMonthReverce(month),
        year,
        isExisting: false,
      });

      DropEndModel.setDraggableData(null);

      UploadQueueModel.setPendingLocalFile(null);
      UploadQueueModel.setPendingLocalBankId(null);
    }
  }

  @action getActiveMonth() {
    let activeMonth = this.months.find(month => month.id === this.activeMonthId);

    return activeMonth;
  }

  @action increaseYear(id) {
    let date = new Date();
    let newMonths = this.months.map(month => {
      if (month.id === id && month.year !== date.getFullYear()) {
        month.year = month.year + 1;
      }
      return month;
    });

    this.months = newMonths;
  }

  @action decreaseYear(id) {
    let newMonths = this.months.map(month => {
      if (month.id === id && month.year !== 1970) {
        month.year = month.year - 1;
      }
      return month;
    });

    this.months = newMonths;
  }

  @action increaseYearForAll() {
    const date = new Date();

    let newMonth = this.months.map(month => {
      if (month.year !== date.getFullYear()) {
        month.year++;
      }
      return month;
    });

    this.months = newMonth;
  }

  @action decreaseYearForAll() {
    let newMonth = this.months.map(month => {
      if (month.year !== 1970) {
        month.year--;
      }
      return month;
    });

    this.months = newMonth;
  }
}

const model = new DatePickerModel();

export default model;
