import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import * as mobx from 'mobx';
import EditBtn from './buttons/EditBtn';
import DeleteBtn from './buttons/DeleteBtn';
import DownloadBtn from './buttons/DownloadBtn';
import SaveBtn from './buttons/SaveBtn';
import CloseBtn from './buttons/CloseBtn';
import SubmitBtn from './buttons/SubmitBtn';

import EditFileDateForm from './forms/EditFileDateForm';
import EditFileDescriptionForm from './forms/EditFileDescriptionForm';

import PopUpModel from '../Models/PopUpModel';
import UpdateModel from '../Models/UpdateModel';
import UploadModel from '../Models/loadModels/UploadModel';
import DownloadModel from '../Models/loadModels/DownloadModel';
import DocPreviewModel from '../Models/DocPreviewModel';

import Helper from '../Helpers/helper';
import { months, getYears, getMonth } from '../Helpers/convertData';

@observer
class ToolbarFileSection extends Component {
  constructor(props) {
    super(props);
    this.YEARS = getYears();
    let defaultMonth = getMonth(this.props.data.month);

    this.state = {
      description: null,
      defaultMonth,
      defaultYear: this.props.data.year,
    };
  }

  handleChangeDescriptionForm(e) {
    const { isEditModeOn } = this.props.data;
    this.setState({
      description: e.target.value,
    });
  }

  closeEditDetail = () => {
    this.setState({ description: '' });
    UpdateModel.closeEditDetail();
  };

  openFullFilePreview() {
    DocPreviewModel.setCurrentLocation(this.props.dest);
    DocPreviewModel.setDocData({ data: this.props.data, subSectionId: this.props.subSectionId });
    DocPreviewModel.toggleFullPreview();
  }

  openPreview() {
    DocPreviewModel.setCurrentLocation(this.props.dest);
    DocPreviewModel.setDocData({ data: this.props.data, subSectionId: this.props.subSectionId });
    DocPreviewModel.togglePreview();
  }

  handleYearChange(e) {
    this.setState({
      defaultYear: +e.target.value,
    });
  }

  handleMonthChange(e) {
    this.setState({
      defaultMonth: e.target.value,
    });
  }

  render() {
    const {
      bank,
      data,
      dest,
      subSectionId,
      isDeletable,
      isUploading,
      provided,
      isOtherDeal,
      parentDest,
      parentSectionId,
    } = this.props;

    const { defaultMonth, defaultYear } = this.state;

    const params = {
      fileData: data,
      dest,
      parentDest,
      parentSectionId,
      subSectionId,
    };

    const detail = (bank && bank.details && bank.details.find(detail => detail.id === data.details_id)) || {
      month: '',
      year: '',
    };
    let isPreviewed = DocPreviewModel.activeDocData
      ? data.id.toString() === DocPreviewModel.activeDocData.id.toString()
      : null;

    return (
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        className={`file-section ${isPreviewed ? 'previewed-file-section' : ''} ${isUploading ? 'uploading' : ''}`}
        onClick={() => this.openPreview()}
        onDoubleClick={() => this.openFullFilePreview()}
      >
        <p className="type">{data.type}</p>
        {/* BANKS TAB */}
        {dest === 'Banks' && data.document_type !== 'Month To Date' && (
          <Fragment>
            <div title={data.title} className="title">
              {Helper.shortenName(data.title, 30)}
              {isDeletable && !data.isEditModeOn && (
                <Fragment>
                  {' '}
                  - {getMonth(data.month)} {data.year}
                </Fragment>
              )}
              {isDeletable && data.isEditModeOn && (
                <Fragment>
                  <EditFileDateForm
                    handleMonthChange={e => this.handleMonthChange(e)}
                    handleYearChange={e => this.handleYearChange(e)}
                    defaultMonth={defaultMonth}
                    defaultYear={defaultYear}
                    detail={detail}
                    fileData={data}
                    subSectionId={subSectionId}
                  />
                </Fragment>
              )}
            </div>
            {isUploading ? (
              <div className="options loader-dots">
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
              </div>
            ) : (
              <div className="options">
                {data.isEditModeOn && (
                  <Fragment>
                    <SubmitBtn
                      className="edit-date"
                      onClick={() =>
                        UpdateModel.changeFileDate(data, subSectionId, this.state.defaultMonth, this.state.defaultYear)
                      }
                    />
                    <CloseBtn onClick={() => UpdateModel.closeEditDetail()} />
                  </Fragment>
                )}
                {(isDeletable || Helper.isBankMonthToDate(dest, subSectionId)) && !data.isEditModeOn && (
                  <EditBtn onClick={e => UpdateModel.handleEditMode(data.id, dest, subSectionId)} />
                )}
                <DownloadBtn onClick={e => DownloadModel.downloadFile(data)} />
                <DeleteBtn onClick={e => PopUpModel.open('deleteFileConfirmation', params)} />
              </div>
            )}
          </Fragment>
        )}
        {/* OTHER TABS */}
        {(dest !== 'Banks' || data.document_type === 'Month To Date') && (
          <Fragment>
            <div title={data.title} className="title">
              <p>{Helper.shortenName(data.title)} </p>
              {data.description && !data.isEditModeOn && (
                <p className="description">{Helper.shortenName(data.description, 40)}</p>
              )}
              {data.isEditModeOn && (
                <EditFileDescriptionForm
                  descr={data.description}
                  onChange={this.handleChangeDescriptionForm.bind(this)}
                />
              )}
            </div>
            <div className="options">
              {data.isEditModeOn && (
                <Fragment>
                  <CloseBtn onClick={() => this.closeEditDetail()} />
                  <SaveBtn
                    onClick={() =>
                      UpdateModel.changeFileDescription(
                        this.state.description,
                        data,
                        dest,
                        subSectionId,
                        parentDest,
                        parentSectionId,
                      )
                    }
                  />
                </Fragment>
              )}
              {!data.isEditModeOn && !isOtherDeal && (
                <EditBtn
                  onClick={() => UpdateModel.handleEditMode(data.id, dest, subSectionId, parentDest, parentSectionId)}
                />
              )}
              <DownloadBtn onClick={e => DownloadModel.downloadFile(data)} />
              {!isOtherDeal && <DeleteBtn onClick={e => PopUpModel.open('deleteFileConfirmation', params)} />}
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

export default ToolbarFileSection;
