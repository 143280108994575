import { action, observable } from 'mobx';
import { NotificationManager } from '../Components/popups/react-notifications/index';

import RootModel from './RootModel';
import DropEndModel from './DropEndModel';
import TrashModel from './TrashModel';
import FileBinModel from './FileBinModel';
import UploadModel from './loadModels/UploadModel';
import NavModel from './NavigationModel';
import DatePickerModel from './DatePickerModel';
import DocPreviewModel from './DocPreviewModel';

import Helper from '../Helpers/helper';
import { DEAL_ID } from '../constants/api';
import fetchData from '../Services/fetchData';
import { getMonth, getMonthReverce } from '../Helpers/convertData';

class UpdateModel {
  @observable folderName = null;
  @observable FolderNameChanging = false;

  async getUpdateBetweenTabsData(file, finishDest, destinationId, source, parentDestination, parentSectionId) {
    let { startDest, startId } = source,
      isMonthToDate = Helper.isBankMonthToDate(finishDest, destinationId);

    let data = {
      id: file.id,
      parent_type: 'Deal',
    };

    if (finishDest === 'Banks' && isMonthToDate) {
      delete file.month;
      delete file.year;
    }

    if (finishDest === 'Banks' && !isMonthToDate) {
      data.document_type = 'General';
      data.parent_type = 'BankAccountDetail';

      if (DocPreviewModel.activeDocData) {
        const { month, year } = DocPreviewModel.selectedItem || {};

        if (month && year) {
          file.month = getMonthReverce(month);
          file.year = year;
        }
      } else if (
        !DocPreviewModel.activeDocData &&
        !file.month &&
        !file.year &&
        (startDest !== 'Banks' || Helper.isBankMonthToDate(startDest, startId)) &&
        !DocPreviewModel.fullPreview
      ) {
        DatePickerModel.toggleDatePicker(destinationId, true);
      }

      let parentID = Helper.getBankDocumentsDateId(destinationId, file.month, file.year);

      data.parent_id = parentID;
    } else if (parentDestination === 'Banks') {
      data.parent_id = parentSectionId;
      data.parent_type = 'BankAccount';
      data.document_type = finishDest;
    } else if (finishDest !== 'FileBinFiles') {
      data.parent_id = DEAL_ID;
      data.document_type = UploadModel.convertDestinationToProp(finishDest, destinationId);
    }

    return data;
  }

  @action async updateDocumentsPlace(file, finishDest, destinationId, source, parentDestination, parentSectionId) {
    let data,
      { startDest, startId } = source;
    file.isEditModeOn = false;

    if (finishDest !== 'FileBinFiles')
      data = await this.getUpdateBetweenTabsData(
        file,
        finishDest,
        destinationId,
        source,
        parentDestination,
        parentSectionId,
      );
    else if (finishDest === 'FileBinFiles') {
      if (FileBinModel.activeFolderId === 'FileBinFiles') {
        data = {
          document_type: 'General',
          id: file.id,
          parent_type: 'Deal',
          parent_id: DEAL_ID,
        };
      } else {
        data = {
          id: file.id,
          parent_type: 'Folder',
          parent_id: FileBinModel.activeFolderId,
        };
      }
    }

    if (startDest === finishDest && startId.toString() !== destinationId.toString()) {
      RootModel.updateSectionFiles(file, finishDest, destinationId, null, parentDestination, parentSectionId);
    }

    if (
      !(
        data.parent_type === 'BankAccountDetail' &&
        (!data.parent_id || typeof data.parent_id == 'object') &&
        !data.year &&
        !data.month
      )
    ) {
      UploadModel.setBankLoadingState(Number(destinationId));
      fetchData('documents/update', data)
        .then(res => res.json())
        .then(res => {
          if (
            data.parent_type === 'BankAccountDetail' &&
            finishDest === startDest &&
            startId.toString() === destinationId.toString()
          ) {
            NotificationManager.success(`${file.title} - ${getMonth(file.month)} ${file.year} has been updated.`);
          } else if (data.parent_id.toString() === TrashModel.trashId.toString()) {
            NotificationManager.success(`${file.title} has been successfully moved to trash!`);
          } else {
            NotificationManager.success(`${file.title} was successfully moved!`);
          }
          window.parent.postMessage({hasChanged: true}, '*');
          DropEndModel.setDraggableData(null);

          return res;
        })
        .catch(err => {
          if (startDest !== finishDest) RootModel.updateSectionFiles(file, startDest, startId, finishDest);
          else if (startDest === finishDest && startId.toString() !== destinationId.toString())
            RootModel.updateSectionFiles(file, startDest, startId);
          NotificationManager.error(`Can not move this file: ${err.message}`);
        })
        .finally(() => {
          UploadModel.setBankLoadingState(false);
        });
    }
  }

  //UPDATE FILES
  @action async changeFileDate(fileData, subSectionId, newMonth, newYear) {
    if (!newMonth && !newYear) {
      fileData.isEditModeOn = false;
      return null;
    }

    let detail = Helper.getBankDocumentsDateId(subSectionId, newMonth, newYear);

    if (!detail) {
      detail = await UploadModel.createBankDetail(subSectionId, newMonth, newYear);
    }

    let data = {
      id: fileData.id,
      parent_type: 'BankAccountDetail',
      parent_id: detail,
    };

    if (typeof data.parent_id === 'number') {
      this.changeFileDateRequest(data, fileData, subSectionId, newMonth, newYear);
    }
  }

  @action changeFileDateRequest(data, fileData, subSectionId, newMonth, newYear) {
    fetchData('documents/update', data)
      .then(res => res.json())
      .then(
        action(res => {
          let success = false;
          let newSection = RootModel.Banks.map(subSection => {
            if (subSection.id === subSectionId) {
              subSection.files.map(file => {
                if (file.id === fileData.id) {
                  if (file.month !== newMonth || file.year !== newYear) success = true;

                  if (newMonth) file.month = getMonthReverce(newMonth);
                  if (newYear) file.year = newYear;
                  file.isEditModeOn = false;
                }
                return file;
              });
            }
            return subSection;
          });

          RootModel.Banks = newSection;
          RootModel.sortFilesByDate(subSectionId); //it doesn't works
          let fileTitle = fileData.title
            .split('.')
            .slice(0, -1)
            .join('-');

          if (success) NotificationManager.success(`${fileTitle} - ${newMonth} ${newYear} has been updated.`);
        }),
      )
      .catch(err => {
        NotificationManager.error(`Updating file date error: ${err.message}`);
      });
  }

  @action closeEditDetail() {
    let newSection;
    if (NavModel.activeTabId !== 'ViewAll') {
      newSection = this.setEditModeOff();
      RootModel[NavModel.activeTabId] = newSection;
    } else {
      ['Banks', 'SubDocs', 'Contracts', 'Stips'].forEach(section => {
        newSection = this.setEditModeOff(section);
        RootModel[section] = newSection;
      });
    }
  }

  setEditModeOff(section) {
    section = section || NavModel.activeTabId;

    return RootModel[section].map(subSection => {
      subSection.files.map(file => {
        file.isEditModeOn = false;
        return file;
      });
      if (subSection.subSections) {
        subSection.subSections.map(sub => {
          sub.files.map(file => {
            file.isEditModeOn = false;
            return file;
          });
          return sub;
        });
      }
      return subSection;
    });
  }

  @action handleEditMode(id, destination, subSectionId, parentDest, parentSectionId) {
    const rootDest = parentDest || destination;
    const rootSectionId = parentSectionId || subSectionId;
    let newSection = RootModel[rootDest].map(subSection => {
      if (subSection.id === rootSectionId) {
        if (parentDest) {
          subSection.subSections.map(sub => {
            if (sub.id === subSectionId) {
              sub.files.map(file => {
                if (file.id === id) {
                  file.isEditModeOn = !file.isEditModeOn;
                } else {
                  file.isEditModeOn = false;
                }
                return file;
              });
            }
            return sub;
          });
        } else {
          subSection.files.map(file => {
            if (file.id === id) {
              file.isEditModeOn = !file.isEditModeOn;
            } else {
              file.isEditModeOn = false;
            }
            return file;
          });
        }
      } else {
        subSection.files.map(file => {
          file.isEditModeOn = false;
          return file;
        });
      }
      return subSection;
    });

    RootModel[rootDest] = newSection;
  }

  @action changeFileDescription(description, fileData, destination, subSectionId, parentDest, parentSectionId) {
    let success = false;
    const rootDest = parentDest || destination;
    const rootSectionId = parentSectionId || subSectionId;

    let newSection = RootModel[rootDest].map(subSection => {
      if (subSection.id === rootSectionId) {
        if (parentDest !== null) {
          subSection.subSections.map(sub => {
            sub.files.map(file => {
              if (file.id === fileData.id) {
                if (description !== fileData.description && !/^\s+$/.test(description)) {
                  file.description = description;
                  success = true;
                }
                file.isEditModeOn = false;
              }
              return file;
            });
            return sub;
          });
        } else {
          subSection.files.map(file => {
            if (file.id === fileData.id) {
              if (description !== fileData.description && !/^\s+$/.test(description)) {
                file.description = description;
                success = true;
              }
              file.isEditModeOn = false;
            }
            return file;
          });
        }
      }
      return subSection;
    });

    if (parentDest === 'Banks') {
      this.updateBankSubDescription(description, fileData, destination, subSectionId);
    } else {
      this.updateFileDescription(description, fileData, destination, subSectionId);
    }

    let fileTitle = fileData.title
      .split('.')
      .slice(0, -1)
      .join('-');
    if (success) NotificationManager.success(`${fileTitle} description has been updated.`);
    RootModel[destination] = newSection;
  }

  updateBankSubDescription(description, fileData, destination, subSectionId) {
    let data = {
      id: fileData.id,
      parent_type: 'BankAccount',
      parent_id: subSectionId,
      document_type: destination,
      description: description,
    };

    fetchData('documents/update', data)
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          NotificationManager.error(`Can not move this file: ${res}`);
          return console.error(res);
        }

        // TODO: add normal success message here, not before

        return res;
      });
  }

  updateFileDescription(description, fileData, destination, subSectionId) {
    let data = {
      id: fileData.id,
      parent_type: 'Deal',
      parent_id: DEAL_ID,
      document_type: UploadModel.convertDestinationToProp(destination, subSectionId),
      description: description,
    };

    fetchData('documents/update', data)
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          NotificationManager.error(`Can not move this file: ${res}`);
          return console.error(res);
        }

        // TODO: add normal success message here, not before

        return res;
      });
  }

  //UPDATE FOLDERS
  @action setFolderName(folderName) {
    this.folderName = folderName;
  }

  @action changeFolderName(name, folder) {
    if (!name.toString()) {
      NotificationManager.warning("Can't be empty");
      return;
    }
    if (folder.name === name) {
      FileBinModel.toggleEditFolder(folder);
      return;
    }

    let data = {
      id: folder.id,
      name: name,
    };

    if (folder.id.toString() === FileBinModel.activeFolderId.toString()) {
      data.parent_id = folder.parent_id !== 'FileBinFiles' ? folder.parent_id : DEAL_ID;
      data.parent_type = folder.parent_id !== 'FileBinFiles' ? 'Folder' : 'Deal';
    } else {
      data.parent_id = FileBinModel.activeFolderId !== 'FileBinFiles' ? FileBinModel.activeFolderId : DEAL_ID;
      data.parent_type = data.parent_id !== DEAL_ID ? 'Folder' : 'Deal';
    }

    fetchData('folders/update', data) // .then(res => res.json())
      .then(res => res.json())
      .then(
        action(res => {
          for (let i = 0; i < FileBinModel.FileBinFolders.length; i++) {
            if (FileBinModel.FileBinFolders[i].id.toString() === folder.id.toString()) {
              FileBinModel.FileBinFolders[i].name = name;
              FileBinModel.FileBinFolders[i].isEditModeOn = false;
              FileBinModel.changeFolderName = false;
              FileBinModel.changingFolderId = null;
              FileBinModel.folderNameChanging = false;
              this.FolderNameChanging = false;

              NotificationManager.success('Folder was successfully renamed');
              return;
            }
          }
        }),
      )
      .catch(err => {
        NotificationManager.error(err.message);
      });
  }
}

const model = new UpdateModel();
export default model;
