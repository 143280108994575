import { observable, action } from 'mobx';
import { NotificationManager } from '../Components/popups/react-notifications/index';
import RootModel from './RootModel';
import PopUpModel from './PopUpModel';
import FileBinModel from './FileBinModel';
import DocPreviewModel from './DocPreviewModel'

import fetchData from '../Services/fetchData';

class TrashModel {
  @observable isInTrash = false;
  @observable trashId = null;
  @observable onDeleteSuccess = null;

  @action setOnDeleteSuccess = (deleteSuccessMethod) => {
    this.onDeleteSuccess = deleteSuccessMethod;
  }

  @action setTrashId(id){
    this.trashId = id;
  }

  @action determineIsInTrash(){
    return (FileBinModel.foldersRoute !== null) ? !!FileBinModel.foldersRoute.find(folder => (folder.id === this.trashId)) : !!this.isInTrash;
  }

  @action toggleTrash() {
    this.isInTrash = !this.determineIsInTrash()

    FileBinModel.activeFolderId = (this.isInTrash) ? this.trashId : 'FileBinFiles';//// TODO: to previous page
    if(this.isInTrash){
      FileBinModel.getSubFolders()
    }

    FileBinModel.updateBreadcrumbs();
  }

  // FOLDERS
  @action moveFolderToTrash(folderData) {
      if(!this.isInTrash){
        let data = {
          id: folderData.id,
          parent_type: "Folder",
          parent_id: this.trashId,
          name: folderData.name
        }

        if(this.trashId){
          fetchData('folders/update', data)
          .then(res => res.json())
          .then(action(res => {

            FileBinModel.FileBinFolders = FileBinModel.FileBinFolders.map(folder => {
                if(folder.id === folderData.id) {
                    folder.parent_id = 'null';//'this.trashId;
                    NotificationManager.success(`${folder.name} has been moved to trash!`);
                }
                return folder;
            });
              window.parent.postMessage({hasChanged: true}, '*');
              this.onDeleteSuccess && this.onDeleteSuccess();
          }))
          .catch(err => {
            NotificationManager.error(`Can't be moved to trash!`);
          })
        }
      }
  }

  @action async removeFolderFromTrash(folderData) {
      fetchData('folders/destroy', {id: folderData.id})
      .then(res => res.json())
      .then(action(res => {

          if(!res.error) {
              FileBinModel.FileBinFolders = FileBinModel.FileBinFolders.filter(folder => folder.id !== folderData.id);
              PopUpModel.closeAll();
              NotificationManager.success(`"${folderData.name}" has been successfully deleted`);
          } else {

          }
      }))
      .catch(err => {
        PopUpModel.closeAll();
        NotificationManager.error(err.message);
      })
  }

  @action async removeDeletingFolderData(folder){
    if(!folder) return null;

    let folders = await FileBinModel.getSubFolders(folder.id, 'Folder');
    let files = await RootModel.getFolderDocuments(null, folder.id);

    if(files){
      files.forEach(file => {
        this.deleteFileFromTrash(file);
      })
    }

    if(folders){
      folders.forEach(async forlder => {
        let subFolders = await FileBinModel.getSubFolders(forlder.id, 'Folder');
        let subFiles = await RootModel.getFolderDocuments(null, forlder.id);
        if(subFiles){
          subFiles.map(file => {
            this.deleteFileFromTrash(file);
          })
        }
        if(subFolders){
          subFolders.map(subFolder => {
            this.removeDeletingFolderData(subFolder);
          })
        }
      })
    }

    return true;
  }

  // FILES
  @action deleteFileFromTrash(fileData) {
      fetchData('documents/destroy', {id: fileData.id})
      .then(res => res.json())
      .then(action(res => {

        RootModel.FileBinFiles = RootModel.FileBinFiles.filter(file => file.id !== fileData.id);
        PopUpModel.closeAll();
        NotificationManager.success(`"${fileData.title}" has been successfully deleted`);
          window.parent.postMessage({hasChanged: true}, '*');
          this.onDeleteSuccess && this.onDeleteSuccess();
      }))
      .catch(err => {
        PopUpModel.closeAll();
        NotificationManager.error(err.message);
      });
  }

  @action moveFileToTrash(fileData, destination, subSectionId, isFromReader, parentDest, parentSectionId) {
    destination = isFromReader ? DocPreviewModel.currentLocation : destination;

      if(destination === 'FileBinFiles' && fileData.parent_id === this.trashId) {
        this.deleteFileFromTrash(fileData);
      } else {
          let data = {
            id: fileData.id,
            parent_type: "Folder",
            parent_id: this.trashId,
            document_type: "General"
          }

          let pickedFile = null;

          if(destination !== 'FileBinFiles'){
              const rootDest = parentDest || destination;
              const rootSectionId = parentSectionId || subSectionId;
            RootModel[rootDest] = RootModel[rootDest].map(subSection => {

              if(subSection.id === rootSectionId) {
                  if (parentDest !== null) {
                      subSection.subSections.map(sect => {
                          if (sect.id === subSectionId) {
                              pickedFile = sect.files.find(file => file.id.toString() === fileData.id.toString());
                              sect.files = sect.files.filter(file => file.id !== fileData.id);
                          }
                          return sect;
                      })
                  } else {
                      pickedFile = subSection.files.find(file => file.id.toString() === fileData.id.toString());
                      subSection.files = subSection.files.filter(file => file.id !== fileData.id);
                  }
              }
              return subSection;
            });
          }

          else if(destination === 'FileBinFiles'){
            pickedFile = RootModel[destination].find(file => file.id.toString() === fileData.id.toString());
          }

          fetchData('documents/update', data)
          .then(res => res.json())
          .then(action(async res => {


            pickedFile.isEditModeOn = false;
            pickedFile.parent_id = this.trashId;// from full preview

            if(destination !== 'FileBinFiles') RootModel.FileBinFiles.push(pickedFile)

            NotificationManager.success(`${fileData.title} has been successfully moved to trash!`);
              window.parent.postMessage({hasChanged: true}, '*');
              this.onDeleteSuccess && this.onDeleteSuccess();
          }))
          .catch(err => {
            NotificationManager.error(`Can't be moved to trash!`);
          })
        }
        if(isFromReader) {
            DocPreviewModel.deleteCurrentFileAfterConfirm();
        }
  };
}

const model = new TrashModel();
export default model;
