import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Droppable } from 'react-beautiful-dnd';

import DownloadBtn from '../buttons/DownloadBtn';
import CollapseBtn from '../buttons/CollapseBtn';

import ToolbarSubsection from '../ToolbarSubsection';
import RootModel from '../../Models/RootModel';
import PopUpModel from '../../Models/PopUpModel';
import DownloadModel from '../../Models/loadModels/DownloadModel';
import NavigationModel from '../../Models/NavigationModel';
import * as mobx from 'mobx';

import Helper from '../../Helpers/helper';
import banksIcon from '../../Images/bank-icon.png';
import addIcon from '../../Images/plus-icon.png';

@observer
class Banks extends Component {
  constructor(props) {
    super(props);

    this.disposer = null;
    this.state = {
      banks: null,
    };
  }

  componentDidMount() {
    this.disposer = mobx.autorun(() => {
      const collapsed = this.props.viewAll
        ? NavigationModel.viewAllCollapsedSections.Banks
        : NavigationModel.collapsedSections.Banks;
      const banks = RootModel.Banks;
      let isEmpty;
      if (banks.length) {
        isEmpty = Helper.isEmptySection(banks);
      }

      this.setState({ banks, isEmpty, collapsed });
    });
  }

  componentWillUnmount() {
    this.disposer();
  }

  render() {
    const { banks, isEmpty, collapsed } = this.state;
    if (!banks) return null;

    return (
      <div className={`toolbar__container ${collapsed ? 'container-collapsed' : ''}`}>
        <div className="head-section">
          <p className="title">
            <CollapseBtn
              collapsed={collapsed}
              onClick={(e, viewAll) => NavigationModel.handleSectionCollapse('Banks', this.props.viewAll)}
            />
            <img src={banksIcon} className="icon" alt="bank" />
            Banks
            <DownloadBtn disabled={isEmpty} onClick={() => DownloadModel.downloadSubsectionFilesInZip('Banks')} />
          </p>
          <button className="add-folder" onClick={e => PopUpModel.open('addBankAccountForm')}>
            <img src={addIcon} className="icon" alt="add" />
            Add Bank Account
          </button>
        </div>

        {!collapsed &&
          banks.map(subsection => {
            return (
              <Fragment key={subsection.id}>
                <Droppable droppableId={subsection.id.toString()}>
                  {(provided, snapshot) => (
                    <ToolbarSubsection
                      provided={provided}
                      dest="Banks"
                      key={subsection.id}
                      data={subsection}
                      isDraggingOver={snapshot.isDraggingOver}
                    />
                  )}
                </Droppable>
              </Fragment>
            );
          })}
      </div>
    );
  }
}

export default Banks;
