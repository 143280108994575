import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';

import CollapseIcon from '../Images/arrow-collapse.png';

import DownloadBtn from './buttons/DownloadBtn';
import EditBtn from './buttons/EditBtn';
import DeleteBtn from './buttons/DeleteBtn';
import UploadBtn from './buttons/UploadBtn';
import ToolbarFileSection from './ToolbarFileSection';
import DatePicker from './containers/DatePicker';
import UploadSubsection from './UploadSubsection';

import FileManagerModel from '../Models/FileManagerModel';
import PopUpModel from '../Models/PopUpModel';
import UploadModel from '../Models/loadModels/UploadModel';
import DownloadModel from '../Models/loadModels/DownloadModel';
import OtherDealsSection from './containers/OtherDealsSection';
import Helper from '../Helpers/helper';
import UploadQueueModel from '../Models/UploadQueueModel';
import DatePickerModel from '../Models/DatePickerModel';

function getContainerStyles(isDropDisabled, isDraggingOver) {
  let borderColor;
  let backgroundColor;

  if (isDropDisabled) {
    borderColor = '#4c95af';
    backgroundColor = '#e8f0f5';
  } else {
    if (isDraggingOver) {
      borderColor = '#4caf50';
      backgroundColor = '#e8f5e9';
    } else {
      borderColor = '#cccccc';
      backgroundColor = '#ffffff';
    }
  }

  return {
    border: `1px solid ${borderColor}`,
    backgroundColor,
    transition: 'background-color 0.2s ease, border-color 0.2s ease',
    padding: 20,
  };
}

@observer
class ToolbarSubsection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
    };
  }

  componentDidMount() {
    UploadModel.setOnUploadSuccess(this.sendMessage);
  }

  throttleSimplified = (func, ms) => {
    let isThrottled = false;
    let shouldRecall = false;

    const wrapper = () => {
      if (isThrottled) {
        shouldRecall = true;
        return;
      }

      func();

      isThrottled = true;

      setTimeout(() => {
        isThrottled = false;
        if (shouldRecall) {
          wrapper();
          shouldRecall = false;
        }
      }, ms);
    };

    return wrapper;
  };

  sendMessage = this.throttleSimplified(() => {
    if (!window.parent) return;
    const message = { uploadSuccess: true };
    window.parent.postMessage(message, '*');
  }, 500);

  handleFilesUpload = async (files, dest, subSectionId, otherDealsCopy, reloadPageOnComplete, additionalData) => {
    if (dest === 'Banks') {
      const isMonthToDate = Helper.isBankMonthToDate(dest, subSectionId);
      if (!isMonthToDate && files.length) {
        UploadQueueModel.setPendingLocalFile(files[0]);
        UploadQueueModel.setPendingLocalBankId(subSectionId);

        DatePickerModel.toggleDatePicker(subSectionId, true);

        return;
      }
    }

    this.setState({ isUploading: true });

    try {
      await UploadModel.handleFilesUpload(
        files,
        dest,
        subSectionId,
        otherDealsCopy,
        reloadPageOnComplete,
        additionalData,
      );
    } catch (error) {
      console.error('File upload failed:', error);
    } finally {
      this.setState({ isUploading: false });
    }
  };

  onDragOver = e => {
    e.preventDefault();
  };

  onDrop = e => {
    e.preventDefault();
    const { dest, data, parentDest } = this.props;
    const files = e.dataTransfer.files;
    this.handleFilesUpload(files, dest, data.id, false, false, { parentDest, ...data });
  };

  render() {
    const { data, dest, provided, parentDest = null, parentSectionId = null } = this.props;
    const { isUploading } = this.state;

    if (
      !data ||
      !data.files ||
      (data.files.length === 0 && data.otherDeals && data.otherDeals.length === 0 && data.deprecated)
    ) {
      return <div ref={provided.innerRef} />;
    }

    const isDropDisabled = data.isDatePickerShown || data.id === UploadModel.isBankLoading;
    const containerStyles = getContainerStyles(isDropDisabled, this.props.isDraggingOver);

    return (
      <div
        className={classNames('subsection', {
          filled: data.files.length > 0,
          collapsed: data.isCollapsed,
          empty: data.files.length === 0,
          bordered: !parentDest,
        })}
		data-testid={`subsection-${data.id}`}
        style={containerStyles}
      >
        <div ref={provided.innerRef} {...provided.droppableProps} onDragOver={this.onDragOver} onDrop={this.onDrop}>
          <div className="subsection__head-section">
            <div className="title">
              {dest === 'Banks' && (
                <Fragment>
                  {Helper.shortenName(data.name)}{' '}
                  {!data.monthToDate && <Fragment> | {data.accountNumber || data.account_number} </Fragment>}
                  {data.monthToDate && data.files.length !== 0 && (
                    <Fragment>
                      <UploadBtn
                        onChange={e => this.handleFilesUpload(e.target.files, dest, data.id)}
                        dest={dest}
                        subSectionData={data}
                        data-testid="upload-btn"
                      />
                    </Fragment>
                  )}
                </Fragment>
              )}

              {dest !== 'Banks' && (
                <Fragment>
                  {data.title}
                  {data.files.length !== 0 && !data.onlySingleFileAllowed && !data.deprecated && (
                    <Fragment>
                      <UploadBtn
                        onChange={e => this.handleFilesUpload(e.target.files, dest, data.id)}
                        dest={dest}
                        subSectionData={data}
                        data-testid="upload-btn"
                      />
                    </Fragment>
                  )}
                </Fragment>
              )}
              {dest === 'Banks' && !data.monthToDate && (
                <EditBtn
                  onClick={() => PopUpModel.open('editBankAccountForm', data)}
                  className="edit-btn-tools"
                  data-testid="edit-btn"
                />
              )}
              {data.files.length !== 0 && !data.hideAllFileDownloads && (
                <DownloadBtn
                  className="banks-download-btn"
                  onClick={() => DownloadModel.downloadSubsectionFilesInZip(dest, data.id)}
                  data-testid="download-btn"
                />
              )}
            </div>
            <div className={`title section_actions ${data.isDeletable ? 'section_deletable-bank' : ''}`}>
              {data.files.length !== 0 && !data.hideFileCollapse && (
                <img
                  onClick={e => FileManagerModel.handleSubsectionCollapse(e, data.id, dest)}
                  className="collapse-btn"
                  src={CollapseIcon}
                  alt="arrow"
                  data-testid="collapse-btn"
                />
              )}
              {data.files.length === 0 && data.isDeletable && (
                <DeleteBtn onClick={() => FileManagerModel.deleteBankAccount(data, dest)} data-testid="delete-btn" />
              )}
            </div>
          </div>

          {data.isDatePickerShown && <DatePicker dest={dest} subSectionData={data} data-testid="date-picker" />}
          {data.files.length === 0 && !data.isDatePickerShown && !data.deprecated && (
            <UploadSubsection
              dest={dest}
              subSectionData={data}
              additionalData={{ parentDest, ...data }}
              data-testid="upload-subsection"
            />
          )}
          {data.files.length !== 0 &&
            !data.isDatePickerShown &&
            data.files.map((file, index) => (
              <Draggable key={file.id} draggableId={file.id} index={index}>
                {provided => (
                  <ToolbarFileSection
                    title={data.name}
                    provided={provided}
                    isDeletable={data.isDeletable}
                    isUploading={file.isUploading}
                    dest={dest}
                    parentDest={parentDest}
                    parentSectionId={parentSectionId}
                    subSectionId={data.id}
                    data={file}
                    data-testid={`file-${file.id}`}
                  />
                )}
              </Draggable>
            ))}

          {data.otherDeals && data.otherDeals.length !== 0 && !data.isDatePickerShown && (
            <OtherDealsSection data={data} dest={dest} data-testid="other-deals-section" />
          )}

          {provided.placeholder}
        </div>
        {data.subSections &&
          data.subSections.length > 0 &&
          data.subSections.map(section => (
            <Droppable key={section.id} droppableId={section.id.toString()} isDropDisabled={isUploading}>
              {provided => (
                <ToolbarSubsection
                  provided={provided}
                  dest={section.dest}
                  data={section}
                  parentSectionId={data.id}
                  parentDest={dest}
                  data-testid={`subsection-${section.id}`}
                />
              )}
            </Droppable>
          ))}
      </div>
    );
  }
}

export default ToolbarSubsection;
